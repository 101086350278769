<template>
	<v-card flat outlined class="rounded-xl d-flex flex-column align-center justify-space-around text-center rewards_card">
		<v-carousel v-model="carousel" hide-delimiters height="200">
			<v-carousel-item v-for="(img) in reward.urls" :key="img">
				<v-img :src="img" height="200" transition="fade-transition">
					<v-col class="pa-0" cols="8">
						<v-card-title class="mgn_header rounded-l-0 rounded-br-lg rounded-tl-xl">{{ reward.title }}</v-card-title>
					</v-col>
				</v-img>
			</v-carousel-item>
		</v-carousel>

		<v-card-subtitle>Category: {{ reward.category }}</v-card-subtitle>

		<v-chip class="mgn_chip justify-center my-1" v-for="shop in reward.points_requirements" :key="shop.streamer_shop">
			{{ shop.points }} {{ shop.shorthand }} points
		</v-chip>

		<v-card-actions class="d-flex justify-center mt-1">
			<v-dialog fullscreen transition="dialog-bottom-transition" v-model="dialogReward">
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-bind="attrs" v-on="on" color="primary" class="mx-1">
						<v-icon left>mdi-pencil</v-icon>
						Edit
					</v-btn>
				</template>

				<v-card class="pa-10">
					<v-app-bar fixed tile color="dark">
						<v-btn icon @click="dialogReward = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
						<v-toolbar-title>{{ title }}</v-toolbar-title>

						<v-spacer></v-spacer>
						<v-btn color="primary" large :loading='loadingSaveBtn' :disabled='loadingSaveBtn' @click="UpdateReward()">
							<v-icon left>mdi-content-save</v-icon>
							Save Reward
						</v-btn>
					</v-app-bar>

					<v-container class="pt-16">
						<h2 class="pt-5">Category: {{ reward.category }} {{ reward.subcategory.name ? ' - ' + reward.subcategory.name : ''}}</h2>

						<v-row class="ma-2">
							<v-col sm="12" md="4" lg="4" xl="4" cols="12">
								<h3 class="mb-3">Main Info</h3>

								<v-text-field
									clearable
									outlined
									:value="title"
									v-model.trim="$v.title.$model"
									:error-messages="titleErrors"
									@input="$v.title.$touch()"
									@blur="$v.title.$touch()"
									label="Title"
									required
								></v-text-field>

								<v-textarea
									outlined
									clearable
									no-resize
									rows="6"
									:value="description"
									v-model.trim="$v.description.$model"
									:error-messages="descriptionErrors"
									@input="$v.description.$touch()"
									@blur="$v.description.$touch()"
									label="Description"
								></v-textarea>
							</v-col>

							<v-col sm="12" md="4" lg="4" xl="4" cols="12">
								<h3 class="mb-3">Points</h3>

								<v-text-field
									outlined
									v-for="shop_streamer in points" 
									:key="shop_streamer.streamer_shop"
									v-model="shop_streamer.points"
									:value="shop_streamer.points"
									:label="shop_streamer.shorthand + ' Points'"
									type="number"
									clearable
									class="mx-2"
								></v-text-field>
							</v-col>

							<v-col sm="12" md="4" lg="4" xl="4" cols="12">
								<h3 class="mb-3">Options</h3>

								<v-text-field
									outlined
									:disabled="!has_max_redeems"
									v-model="max_redeems"
									:value="max_redeems"
									label="Max redeems"
									type="number"
									clearable
								></v-text-field>

								<v-text-field
									outlined
									:disabled="!repeatable"
									v-model="cooldown"
									:value="cooldown"
									label="Reward Cooldown (in minutes)"
									type="number"
									clearable
								></v-text-field>

								<v-row>
									<v-col cols="6">
										<v-checkbox v-model="repeatable" label="Repeatable"></v-checkbox>
									</v-col>

									<v-col cols="6">
										<v-checkbox v-model="steam_auth_required" label="Steam Auth Required"></v-checkbox>
									</v-col>

									<v-col cols="6">
										<v-checkbox v-model="has_max_redeems" label="Max Redeems"></v-checkbox>
									</v-col>

									<v-col cols="6">
										<v-checkbox v-model="has_multiple_redeems" label="Multiple Redeems"></v-checkbox>
									</v-col>

									<v-col cols="6">
										<v-checkbox v-model="notify_in_chat" label="Send Chat Alert"></v-checkbox>
									</v-col>
								</v-row>
							</v-col>
						</v-row>

						<v-divider class="my-3"></v-divider>

						<v-row>
							<v-col sm="12" md="12" lg="6" xl="6" cols="12">
								<v-card flat tile outlined class="ma-5 pa-10 rounded-xl">
									<h2>Images - {{ images_urls.length }} total</h2>

									<v-tooltip right>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												v-bind="attrs" 
												v-on="on"
												@click="AddImageURL"
												small 
												fab
												color="pink"
												class="my-3"
											>
												<v-icon>mdi-plus</v-icon>
											</v-btn>
										</template>
										<span>Add New Image URL</span>
									</v-tooltip>
									<v-row>
										<v-col cols="12" v-for="(url, index) in images_urls" :key="index" class="justify-center">
											<v-text-field
												v-model="images_urls[index]"
												:value="images_urls[index]"
												label="Reward image URL"
												clearable
											></v-text-field>
											<v-img :src="images_urls[index]" width="800"></v-img>
										</v-col>
									</v-row>
								</v-card>
							</v-col>

							<v-col sm="12" md="12" lg="6" xl="6" cols="12">
								<v-card flat tile outlined class="ma-5 pa-10 rounded-xl">
									<v-container>
										<v-row>
											<h2>Redeemers List - {{ redeemers.length }} total</h2>
											<v-spacer></v-spacer>
											<v-btn class="mx-1" color="purple" @click="RollRedeemer()" :loading="loadingRollBtn" :disabled="loadingRollBtn">
												<v-icon>mdi-dice-multiple-outline</v-icon>
												Roll
											</v-btn>
											<v-btn class="mx-1" color="red" @click="rolled = null">Clear</v-btn>
										</v-row>

										<v-col col="12" class="my-8" v-if="rolled" align="center" justify="center">
											<h2>{{ rolled.user }}</h2>
										</v-col>
									</v-container>
									
									<v-divider class="my-3"></v-divider>
									<v-virtual-scroll height="700" :items="redeemers" item-height="20">
										<template v-slot:default="{ item }">
											<v-container>
												<v-row>
													<v-col>
														<p class="ma-0">{{ item.user }}</p>
													</v-col>
													<v-col>
														<p class="ma-0">{{ item.date_redeemed }}</p>
													</v-col>
												</v-row>
											</v-container>
										</template>
									</v-virtual-scroll>
								</v-card>
							</v-col>
						</v-row>
					</v-container>
				</v-card>
			</v-dialog>

			<v-btn v-if="!reward.disabled" class="mx-1" color="warning" :loading="loadingToggleBtn" :disabled="loadingToggleBtn" @click="ToggleReward(reward.id)">
				<v-icon left>mdi-gift-off-outline</v-icon>
				Disable
			</v-btn>
			<v-btn v-else color="success" class="mx-1" :loading="loadingToggleBtn" :disabled="loadingToggleBtn" @click="ToggleReward(reward.id)">
				<v-icon left>mdi-gift-outline</v-icon>
				Enable
			</v-btn>

			<v-dialog v-model="dialogDelete" max-width="450px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-bind="attrs" v-on="on" color="error" class="mx-1">
						<v-icon left>mdi-delete</v-icon>
						Delete
					</v-btn>
				</template>

				<v-card>
					<v-card-title>
						<v-icon class="mr-2">mdi-alert</v-icon>
						{{ reward.title }}
					</v-card-title>

					<v-card-text>
						Are you sure you want to delete this reward?
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="secondary" @click="dialogDelete = false">Cancel</v-btn>
						<v-btn color="error" :loading="loadingDeleteBtn" :disabled="loadingDeleteBtn" @click="DeleteReward(reward.id)">Delete</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</v-card>
			</v-dialog>

		</v-card-actions>
	</v-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
	props: ['reward'],

	data() {
		return {
			rolled: null,
			carousel: 0,
			dialogDelete: false,
			dialogDisable: false,
			dialogReward: false,
			dialogImages: false,
			dialogUserList: false,
			title: this.reward.title,
			description: this.reward.description,
			points: this.reward.points_requirements,
			redeems: this.reward.redeems,
			redeemers: this.reward.redeemers,
			repeatable: this.reward.repeatable,
			steam_auth_required: this.reward.steam_auth_required,
			cooldown: Math.floor(this.reward.cooldown / 60),
			images_urls: this.reward.urls,
			max_redeems: this.reward.max_redeems,
			has_max_redeems: this.reward.has_max_redeems,
			has_multiple_redeems: this.reward.has_multiple_redeems,
			notify_in_chat: this.reward.notify_in_chat,
			loadingDeleteBtn: false,
			loadingToggleBtn: false,
			loadingSaveBtn: false,
			loadingRollBtn: false
		}
	},

	validations: {
		title: {
			required
		},
		description: {
			required
		},
	},

	computed: {
		titleErrors() {
			const errors = [];
			if (!this.$v.title.$dirty) return errors;
			!this.$v.title.required && errors.push('Title is required.');
			return errors;
		},

		descriptionErrors() {
			const errors = [];
			if (!this.$v.description.$dirty) return errors;
			!this.$v.description.required && errors.push('Description is required.');
			return errors;
		}
	},

	methods: {
		AddImageURL () {
			this.images_urls.push('');
		},

		async RollRedeemer() {
			try {
				this.loadingRollBtn = true;
				const request = await fetch(this.$store.state.base_url + '/rewards/roll', {
					method: 'POST',
					body: JSON.stringify({
						id: this.reward.id,
					}),
					headers: {
						"Content-type" : "application/json; charset=UTF-8",
						"Authorization" : this.$cookies.get('mgn_sessid'),
					}
				});

				const res = await request.json();

				if (request.ok && request.status === 200) {	
					if (res.winner) {
						this.rolled = res.winner;
					}
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: 'There was an unexpected error.'
				});
			}
			finally {
				this.loadingRollBtn = false;
			}
		},

		async UpdateReward() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				try {
					this.loadingSaveBtn = true;

					const request = await fetch(this.$store.state.base_url + '/rewards/update', {
						method: 'PUT',
						body: JSON.stringify({
							id: this.reward.id,
							title: this.title,
							description: this.description,
							points: this.points,
							repeatable: this.repeatable,
							steam_auth_required: this.steam_auth_required,
							urls: this.images_urls,
							max_redeems: this.max_redeems,
							has_max_redeems: this.has_max_redeems,
							has_multiple_redeems: this.has_multiple_redeems,
							notify_in_chat: this.notify_in_chat,
							cooldown: this.cooldown
						}),
						headers: {
							"Content-type" : "application/json; charset=UTF-8",
							"Authorization" : this.$cookies.get('mgn_sessid'),
						}
					});

					const res = await request.json();

					if (request.ok && request.status === 200) {	
						if (res.isRewardUpdated) {
							this.$emit('RewardUpdated');

							this.$notify({
								group: 'main',
								type: 'success',
								title: this.$store.state.app_name,
								text: 'Reward updated successfully!'
							});

							this.dialogReward = false;
						}
						else if (res.isRewardUnchanged) {
							this.$notify({
								group: 'main',
								type: 'warn',
								title: this.$store.state.app_name,
								text: 'Reward is already saved! Nothing changed.'
							});

							this.dialogReward = false;
						}
					}
					else if (request.status === 401) {
						res.forEach(e => {
							this.$notify({
								group: 'main',
								type: 'error',
								title: this.$store.state.app_name,
								text: e.error
							});
						});
					}
					else {
						if (res.error) {
							if (this.$store.state.error_types.includes(res.error)) {
								this.sessionExpiredHandler(res);
							}
							else {
								throw res.error;
							}
						}
					}
				}
				catch (error) {
					this.$notify({
						group: 'main',
						type: 'error',
						title: this.$store.state.app_name,
						text: 'There was an unexpected error :('
					});
				}
				finally {
					this.loadingSaveBtn = false;
				}
			}
		},

		async DeleteReward(reward_id) {
			try {
				this.loadingDeleteBtn = true;

				const request = await fetch(this.$store.state.base_url + '/rewards/delete', {
					method: 'DELETE',
					body: JSON.stringify({
						reward_id: reward_id
					}),
					headers: {
						"Content-type" : "application/json; charset=UTF-8",
						"Authorization" : this.$cookies.get('mgn_sessid'),
					}
				});

				const res = await request.json();

				if (request.ok && request.status === 200) {	
					if (res.isRewardDeleted) {
						this.$emit('RewardDeleted');
						this.$notify({
							group: 'main',
							type: 'success',
							title: this.$store.state.app_name,
							text: 'Reward deleted successfully!'
						});
					}
					else {
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: res.error
						});
					}
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: 'There was a network error. Please try again.'
				});
			}
			finally {
				this.loadingDeleteBtn = false;
			}
		},

		async ToggleReward(reward_id) {
			try {
				this.loadingToggleBtn = true;

				const request = await fetch(this.$store.state.base_url + '/rewards/toggle', {
					method: 'PUT',
					body: JSON.stringify({
						reward_id: reward_id
					}),
					headers: {
						"Content-type" : "application/json; charset=UTF-8",
						"Authorization" : this.$cookies.get('mgn_sessid'),
					}
				});

				const res = await request.json();

				if (request.ok && request.status === 200) {	
					if (res.isRewardStatusChanged) {
						let msg = res.disabled ? 'Reward disabled!' : 'Reward enabled!';

						this.$notify({
							group: 'main',
							type: 'success',
							title: this.$store.state.app_name,
							text: msg
						});
						
						this.$emit('RewardUpdated');
					}
					else {
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: res.error
						});
					}
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: 'There was a network error. Please try again.'
				});
			}
			finally {
				this.loadingToggleBtn = false;
			}
		}
	},
}
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

tr:hover {
	background-color: transparent !important;
}
</style>