<template>
	<v-col cols="12" class="d-flex flex-column align-self-stretch pa-0 rounded-lg">
		<v-card flat tile :loading="rewardsLoading">
			<v-row class="pa-10 pb-0 justify-space-between align-center">
				<h2>Rewards - {{ rewards.length }} Total</h2>
				<v-dialog v-model="dialog" max-width="650px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn elevation="0" small fab v-bind="attrs" v-on="on" color="pink" class="mx-3">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</template>
					<NewReward 
						:streamers="$store.state.streamers" 
						@NewRewardAdded="UpdateRewards" 
						@CloseDialog="dialog = false"
					/>
				</v-dialog>

				<v-spacer></v-spacer>

				<v-col class="d-flex flex-column align-end">
					<small>*Disabling a reward will not appear in Dashboard page and cannot be redeemed.</small>
				</v-col>
			</v-row>

			<v-row class="pa-11 pt-0">
				<v-col sm="12" md="6" lg="4" xl="3" cols="12" v-for="reward in rewards" :key="reward.id">
					<EditReward
						:reward="reward"
						@RewardDeleted="UpdateRewards"
						@RewardUpdated="UpdateRewards"
					/>
				</v-col>
			</v-row>
		</v-card>
	</v-col>
</template>

<script>
import EditReward from './EditReward.vue';
import NewReward from './NewReward.vue';

export default {
	components: {
		NewReward,
		EditReward
	},

	data() {
		return {
			dialog: false,
			rewards: [],
			rewardsLoading: true,
			carousel: 0
		}
	},

	async mounted() {
		await this.fetchRewards();
	},

	methods: {
		UpdateRewards: async function() {
			this.dialog = false;
			await this.fetchRewards();
		},

		fetchRewards: async function() {
			try {
				const request = await fetch(this.$store.state.base_url + '/rewards', {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('mgn_sessid'),
					}
				});
				const res = await request.json();

				if (request.ok) {
					if (res.rewards) this.rewards = res.rewards;
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: 'MG Network',
					text: error.message
				});
			}
			finally {
				this.rewardsLoading = false;
			}
		},
	},
}
</script>